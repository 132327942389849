import ApiService from "@/services";
import { AxiosResponse } from "axios";
import NewCostingPdfMarkerInterface from "@/types/costing/NewCostingPdfMarkerInterface";

export default class CostingPdfMarkerService {
  static getCostingPdfMarkerList(costingId: string): Promise<AxiosResponse> {
    return ApiService.get("/v2/costing-marks", {
      params: {
        costingId,
        skip: 0,
        limit: 99999,
      },
    });
  }

  static addCostingPdfMarker(
    payload: NewCostingPdfMarkerInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/costing-marks", payload);
  }

  static updateCostingPdfMarker(
    id: string,
    payload: Omit<NewCostingPdfMarkerInterface, "costingId" | "type">
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/costing-marks/${id}`, payload);
  }
}
