import { highlightQueryTextSplitter } from "@/regex/highlightQueryTextSplitter";
import { costingTableNameEnum } from "@/enums/costing/costingTableNameEnum";
import QuoteService from "@/services/QuoteService";
import CostingKeywordInterface from "@/types/costing/CostingKeywordInterface";

export const getMatches = (query: string, text: string) => {
  const queryArray = getFormattedArray(query);
  const textArray = getFormattedArray(text);
  return Array.from(
    new Set([
      ...queryArray.filter((queryEl) =>
        textArray.find((textEl) =>
          textEl.toLowerCase().includes(queryEl.toLowerCase())
        )
      ),
    ])
  ).sort((a, b) => b.length - a.length);
};

export const getFormattedArray = (text: string) => {
  return text
    .trim()
    .split(highlightQueryTextSplitter)
    .filter((item) => item !== "");
};

export const getPartCostingKeywords = (tableName: costingTableNameEnum) => {
  return QuoteService.getPartCostingKeywords({
    tableName,
  });
};

export const getFilteredKeywordIds = (
  keywords: CostingKeywordInterface[],
  searchInput: string
) => {
  return (
    keywords
      .filter((item) => item.keyword === searchInput)
      ?.map((item) => item.targetId) || []
  );
};

export const getSortedListWithKeywords = (
  list: Record<string, any>[],
  keywords: CostingKeywordInterface[],
  searchInput: string
) => {
  return list
    ?.filter((item) =>
      getFilteredKeywordIds(keywords, searchInput)?.includes(item.id)
    )
    .map((rawMaterial) => {
      return {
        ...rawMaterial,
        selectionCounter:
          keywords.find((item) => item.targetId === rawMaterial.id)
            ?.selectionCounter || 0,
      };
    })
    .sort((a, b) => {
      return b.selectionCounter - a.selectionCounter;
    });
};

export const getMatchPercentByEquivalentSpecs = (
  listItem: Record<string, any>,
  searchInput: string
) => {
  return Math.max(
    ...listItem.equivalentSpecs.map(
      (item: { relativeStandardName: string }) =>
        getMatches(searchInput, item.relativeStandardName).join(" ").length /
        item.relativeStandardName?.length
    )
  );
};

export const getMatchPercent = (
  listItem: Record<string, any>,
  searchInput: string,
  keys: string[]
) => {
  const searchLine = keys.map((key) => listItem?.[key]).join(" ");
  return (
    getMatches(searchInput, searchLine).join(" ").length / searchLine?.length
  );
};

export const getSortedListWithoutKeywords = (
  list: Record<string, any>[],
  searchInput: string,
  keys = ["name"],
  checkEquivalentSpecs = false
) => {
  return [...list]
    .sort((a, b) => {
      if (
        checkEquivalentSpecs &&
        getMatchPercent(a, searchInput, keys) <
          getMatchPercentByEquivalentSpecs(a, searchInput)
      ) {
        return getMatchPercentByEquivalentSpecs(a, searchInput) >
          getMatchPercentByEquivalentSpecs(b, searchInput)
          ? -1
          : 1;
      }
      return getMatchPercent(a, searchInput, keys) >
        getMatchPercent(b, searchInput, keys)
        ? -1
        : 1;
    })
    .sort((a, _b) =>
      checkEquivalentSpecs &&
      getMatchPercentByEquivalentSpecs(a, searchInput) === 1
        ? -1
        : 0
    );
};
