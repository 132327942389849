import { PartCosting } from "@/types/costing/PartCosting";

export default () => {
  const getCalculatedPayload = (
    data: {
      gap: number;
      isGapActive: boolean;
      rate: number;
      isRateActive: boolean;
    },
    isAdminSummary: boolean,
    partCosting: PartCosting
  ) => {
    if (isAdminSummary) {
      return {
        adjustmentCostGap: +partCosting.adjustmentCostGap,
        adjustmentPriceGap: data.gap / 100,
        isCostGapActive: partCosting.isCostGapActive,
        isPriceGapActive: data.isGapActive,
        adjustmentCost: +partCosting.adjustmentCost || 0,
        adjustmentPrice: data.rate / 100,
        isAdjustmentCostActive: partCosting.isAdjustmentCostActive,
        isAdjustmentPriceActive: data.isRateActive,
      };
    }
    return {
      adjustmentCostGap: data.gap / 100,
      adjustmentPriceGap: +partCosting.adjustmentPriceGap,
      isCostGapActive: data.isGapActive,
      isPriceGapActive: partCosting.isPriceGapActive,
      adjustmentCost: data.rate / 100,
      adjustmentPrice: +partCosting.adjustmentPrice,
      isAdjustmentCostActive: data.isRateActive,
      isAdjustmentPriceActive: partCosting.isAdjustmentPriceActive,
    };
  };
  return {
    getCalculatedPayload,
  };
};
