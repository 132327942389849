import { ref, watch } from "vue";

export default (
  isMarkerDisplayed: boolean,
  onTogglePdfTextMarker: () => void
) => {
  const showPdfTextMarker = ref(false);

  watch(
    () => isMarkerDisplayed,
    () => {
      if (!isMarkerDisplayed && showPdfTextMarker.value) {
        showPdfTextMarker.value = false;
      }
    }
  );

  const onPdfTextMarkerDisplayButtonClick = () => {
    showPdfTextMarker.value = !showPdfTextMarker.value;
    onTogglePdfTextMarker();
  };

  return {
    showPdfTextMarker,
    onPdfTextMarkerDisplayButtonClick,
  };
};
