import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98c908ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-with-uneditable-state" }
const _hoisted_2 = {
  key: 1,
  class: "uneditable-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.isNotEditable)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
          _createElementVNode("span", null, _toDisplayString($setup.valueText), 1)
        ], true)
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString($setup.valueText), 1),
          _renderSlot(_ctx.$slots, "showButton", {}, undefined, true)
        ]))
  ]))
}