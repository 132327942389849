enum CostingMarkerTextTypeEnum {
  MATERIAL = "material",
  PART_NUMBER = "pn",
  REVISION = "revision",
  SURFACE_TREATMENT = "surface treatment",
  SURFACE_FINISH = "surface finish",
  HEAT_TREATMENT = "heat treatment",
  BUY_ITEMS = "buy items",
}

export default CostingMarkerTextTypeEnum;
