import { ref } from "vue";

export default () => {
  const errorMap = ref<Map<string, string>>(new Map());

  const onValidationReject = (
    reject: (reason?: Error) => void,
    fieldName: string,
    errorMessage: string
  ) => {
    reject(Error(errorMessage));
    errorMap.value.set(fieldName, errorMessage);
  };

  const onValidationResolve = (
    resolve: (value: void | PromiseLike<void>) => void,
    fieldName: string
  ) => {
    resolve();
    errorMap.value.delete(fieldName);
  };

  return {
    errorMap,
    onValidationReject,
    onValidationResolve,
  };
};
