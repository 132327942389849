import { Ref } from "vue";
import { FormItemRule } from "naive-ui";
import {
  maxFractionalDigitsMessage,
  maxSafeIntegerValueMessage,
  minValueMessage,
  requiredMessage,
} from "@/composables/validationMessages";
import { getNumberFromThousandWithCommas } from "@/composables/maskComposable";

const digitNumberVerificationRules = (
  fieldName: string,
  isDirty: Ref<boolean>,
  errorMap: Ref<Map<string, string>>,
  onValidationReject: (
    reject: (reason?: Error) => void,
    fieldName: string,
    errorMessage: string
  ) => void,
  onValidationResolve: (
    resolve: (value: void | PromiseLike<void>) => void,
    fieldName: string
  ) => void
): FormItemRule[] => {
  return [
    {
      required: true,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!isDirty.value) {
            onValidationResolve(resolve, fieldName);
            return;
          }
          const numValue = getNumberFromThousandWithCommas(value);
          if (!value) {
            onValidationReject(reject, fieldName, requiredMessage(fieldName));
          } else if (numValue < 0) {
            onValidationReject(
              reject,
              fieldName,
              minValueMessage(fieldName, 0)
            );
          } else if (numValue > Number.MAX_SAFE_INTEGER) {
            onValidationReject(
              reject,
              fieldName,
              maxSafeIntegerValueMessage(fieldName)
            );
          } else if (!/^(\d+)(\.\d)?$/g.test(`${numValue}`)) {
            onValidationReject(
              reject,
              fieldName,
              maxFractionalDigitsMessage(fieldName, 1)
            );
          } else {
            onValidationResolve(resolve, fieldName);
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default digitNumberVerificationRules;
