import { h } from "vue";
import { NButton } from "naive-ui";

export default () => {
  const renderHeader = (title: string, onClickHeader: () => void) => {
    return () =>
      h(
        "div",
        {
          class: "filters-menu-header",
          onClick: () => {
            onClickHeader();
          },
        },
        [
          h("button", {
            class: "close-button",
          }),
          title,
        ]
      );
  };

  const renderHeaderForPicker = (
    title: string,
    costingMarkId: string | null | undefined,
    showPdfTextMarker: boolean,
    onClickHeader: () => void
  ) => {
    return () =>
      h(
        "div",
        {
          class: "filters-menu-header",
          style: "cursor: default; justify-content: space-between",
        },
        [
          title,
          costingMarkId
            ? h(
                NButton,
                {
                  class: ["show-pdf-text-marker-button", "text-button"],
                  text: true,
                  onClick: () => {
                    onClickHeader();
                  },
                },
                () => (showPdfTextMarker ? "Hide" : "Show")
              )
            : undefined,
        ]
      );
  };
  return { renderHeader, renderHeaderForPicker };
};
