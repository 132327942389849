export const CostingQualityControlInspectionStandard = {
  Reduced: {
    label: "Z1.4 AQL GI (Reduced)",
    value: "ANSI_Z1_4_AQL_GI_REDUCED",
  },
  Normal: {
    label: "Z1.4 AQL GII (Normal)",
    value: "ANSI_Z1_4_AQL_GII_NORMAL",
  },
  Tighten: {
    label: "Z1.4 AQL GIII (Tighten)",
    value: "ANSI_Z1_4_AQL_GIII_TIGHTEN",
  },
  OnePiece: {
    label: "1 Piece",
    value: "PERCENT_100_FOR_CTQ",
  },
  FullInspection: {
    label: "100% Inspection",
    value: "INSPECTION_PERCENT_100",
  },
  NotRequired: {
    label: "Not required",
    value: "NOT_REQUIRED",
  },
};
