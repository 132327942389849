enum CostingProductionProcessEnum {
  MANUFACTURING = "MANUFACTURING",
  QUALITY_CONTROL = "QUALITY_CONTROL",
  ADMINISTRATIVE = "ADMINISTRATIVE",
  CUSTOM_PROCESS = "CUSTOM_PROCESS",
  PACKING = "PACKING",
  DELIVERY = "DELIVERY",
}

export default CostingProductionProcessEnum;
