import { Ref } from "vue";
import { FormItemRule } from "naive-ui";
import {
  minMaxLengthMessage,
  requiredMessage,
} from "@/composables/validationMessages";

const stringVerificationRules = (
  fieldName: string,
  isDirty: Ref<boolean>,
  errorMap: Ref<Map<string, string>>,
  onValidationReject: (
    reject: (reason?: Error) => void,
    fieldName: string,
    errorMessage: string
  ) => void,
  onValidationResolve: (
    resolve: (value: void | PromiseLike<void>) => void,
    fieldName: string
  ) => void
): FormItemRule[] => {
  return [
    {
      required: true,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!isDirty.value) {
            onValidationResolve(resolve, fieldName);
            return;
          }
          if (!value?.length) {
            onValidationReject(reject, fieldName, requiredMessage(fieldName));
          }
          if (value.length < 3 || value.length > 255) {
            onValidationReject(
              reject,
              fieldName,
              minMaxLengthMessage(fieldName, 3, 255)
            );
          } else {
            onValidationResolve(resolve, fieldName);
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default stringVerificationRules;
