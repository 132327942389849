import ApiService from "@/services";
import { AxiosResponse } from "axios";

export default class ManufacturingProcessService {
  static getManufacturingProcessListWithRelations(): Promise<AxiosResponse> {
    return ApiService.get("/v2/manufacturing-processes/with-relations", {
      params: { skip: 0, limit: 99999 },
    });
  }
}
