import { getThousandWithCommas } from "@/composables/maskComposable";

const events = ["input", "change", "focus", "blur"];
const vThousandMask = {
  beforeMount(el: any): void {
    const inputEl =
      el.tagName.toLowerCase() === "input" ? el : el.querySelector("input");
    inputEl.onInput = () => {
      let timerId: NodeJS.Timeout | null = setInterval(() => checkValue(), 100);
      const checkValue = () => {
        if (inputEl.value !== getThousandWithCommas(inputEl.value || "")) {
          inputEl.value = getThousandWithCommas(inputEl.value || "");
          inputEl.dispatchEvent(new Event("input"));
          if (timerId) {
            clearInterval(timerId);
            timerId = null;
          }
        }
      };
      checkValue();
      setTimeout(() => {
        if (timerId) {
          clearInterval(timerId);
        }
      }, 500);
    };

    events.forEach((event) => {
      inputEl.addEventListener(event, inputEl.onInput);
    });
    inputEl.value = getThousandWithCommas(inputEl.value || "");
  },
  unmounted(el: any): void {
    const inputEl =
      el.tagName.toLowerCase() === "input" ? el : el.querySelector("input");
    events.forEach((event) => {
      inputEl.removeEventListener(event, inputEl.onInput);
    });
  },
};

export default vThousandMask;
