import { computed, ref, Ref, watch } from "vue";
import useGetAdjustmentCalculatedPayload from "@/composables/costing/useGetAdjustmentCalculatedPayload";
import { costingSummaryRoleEnum } from "@/enums/costing/costingSummaryRoleEnum";
import QuoteService from "@/services/QuoteService";
import { CostingSummaryQuantityInterface } from "@/types/costing/CostingSummaryQuantityInterface";
import CostingAdjustmentPreviewInterface, {
  AdminSummariesAdjustedInterface,
  MfrSummariesAdjustedInterface,
} from "@/types/costing/CostingAdjustmentPreviewInterface";
import {
  CostingCostAdjustmentMarkupInterface,
  CostingPriceAdjustmentMarkupInterface,
} from "@/types/costing/CostingMarkupInterface";
import { PartCosting } from "@/types/costing/PartCosting";
import { SetAdjustmentPerQuantityPayload } from "@/types/api/SetAdjustmentPerQuantityPayload";
import { SetMinAdjustmentGapPayload } from "@/types/api/SetMinAdjustmentGapPayload";

export default (
  partCosting: Ref<PartCosting>,
  costingId: Ref<string>,
  currencyId: Ref<string>,
  mfrRegionId: Ref<string>,
  costingQuantityListWithAdjustments: Ref<CostingSummaryQuantityInterface[]>,
  adjustedMarkups: Ref<
    CostingCostAdjustmentMarkupInterface | CostingPriceAdjustmentMarkupInterface
  >
) => {
  const isAdminSummary = computed(() => {
    return (
      costingQuantityListWithAdjustments?.value[0]?.role ===
      costingSummaryRoleEnum.ADMIN
    );
  });

  watch(
    () => isAdminSummary.value,
    () => {
      setDefaultValues();
    }
  );

  const showAddAdjustmentMenu = ref(false);
  const adjustmentGap = ref(
    isAdminSummary.value
      ? partCosting.value?.adjustmentPriceGap
      : partCosting.value?.adjustmentCostGap || 0
  );
  const isGapActive = ref(
    isAdminSummary.value
      ? !!partCosting.value?.isPriceGapActive
      : !!partCosting.value?.isCostGapActive
  );
  const adjustmentCost = ref(
    isAdminSummary.value
      ? partCosting.value?.adjustmentPrice
      : partCosting.value?.adjustmentCost || 0
  );
  const isAdjustmentCostActive = ref(
    isAdminSummary.value
      ? !!partCosting.value?.isAdjustmentPriceActive
      : !!partCosting.value?.isAdjustmentCostActive
  );
  const explanationCost = ref(
    isAdminSummary.value
      ? partCosting.value?.explanationPrice
      : partCosting.value?.explanationCost || ""
  );

  const calculatedPayload = ref<SetMinAdjustmentGapPayload | null>();
  const adjustmentPerQuantityPayload = ref<SetAdjustmentPerQuantityPayload[]>(
    [] as SetAdjustmentPerQuantityPayload[]
  );
  const adjustmentPerQuantityPayloadAnotherRegion = ref<
    SetAdjustmentPerQuantityPayload[]
  >([] as SetAdjustmentPerQuantityPayload[]);

  const isGapModified = computed(
    () => isGapActive.value || +adjustmentGap.value !== 0
  );
  const isRateModified = computed(
    () => isAdjustmentCostActive.value || +adjustmentCost.value !== 0
  );
  const isAdjustmentModified = computed(() => {
    return (
      isRateModified.value ||
      costingQuantityListWithAdjustments.value.some(
        (item) => +item.adjustment !== 0
      )
    );
  });

  const { getCalculatedPayload } = useGetAdjustmentCalculatedPayload();

  const onCalculateSummaryPreview = (data: {
    gap: number;
    isGapActive: boolean;
    rate: number;
    isRateActive: boolean;
  }) => {
    adjustmentGap.value = `${data.gap / 100}`;
    isGapActive.value = data.isGapActive;
    isAdjustmentCostActive.value = data.isRateActive;

    calculatedPayload.value = getCalculatedPayload(
      data,
      isAdminSummary.value,
      partCosting.value
    );

    return QuoteService.getSummaryPreviewWithAdjustmentPerQuantity(
      costingId.value,
      adjustmentPerQuantityPayload.value.map((item) => ({
        costingSummaryId: item.costingSummaryId,
        adjustment: item.adjustment,
      })),
      calculatedPayload.value,
      currencyId.value
    ).then((res) => {
      onUpdateCostingQuantityListWithAdjustments(res);
    });
  };

  const onUpdateCostingQuantityListWithAdjustments = (res: {
    data: { data: CostingAdjustmentPreviewInterface };
  }) => {
    const preview = getPreviewByRegion(res.data.data);
    const adjustedValue = preview?.adjusted;
    const markupValue = preview?.markups;
    if (adjustedValue) {
      costingQuantityListWithAdjustments.value = adjustedValue;
      setInitAdjustmentPerQuantityPayload();
    }
    if (markupValue) {
      adjustedMarkups.value = markupValue;
    }

    const previewAnotherRegion = getPreviewByRegion(res.data.data, false);
    const adjustedValueAnotherRegion = previewAnotherRegion?.adjusted;
    if (adjustedValueAnotherRegion) {
      adjustmentPerQuantityPayloadAnotherRegion.value =
        adjustedValueAnotherRegion.map(
          (item: CostingSummaryQuantityInterface) => ({
            costingSummaryId: item.id,
            adjustment: +item.adjustment,
            flatRateAdjustment: +item.flatRateAdjustment,
            totalAdjustment: +item.totalAdjustment,
          })
        );
    }
  };

  const getPreviewByRegion = (
    data: CostingAdjustmentPreviewInterface,
    currentRegion = true
  ) => {
    return isAdminSummary.value
      ? getDataByRegion(data.adminSummariesAdjusted, currentRegion)
      : getDataByRegion(data.mfrSummariesAdjusted, currentRegion);
  };

  const getDataByRegion = (
    data: (AdminSummariesAdjustedInterface | MfrSummariesAdjustedInterface)[],
    currentRegion: boolean
  ) => {
    return data.find((item) =>
      currentRegion
        ? item.mfrRegionId === mfrRegionId.value
        : item.mfrRegionId !== mfrRegionId.value
    );
  };

  const setInitAdjustmentPerQuantityPayload = () => {
    adjustmentPerQuantityPayload.value =
      costingQuantityListWithAdjustments.value.map((item) => ({
        costingSummaryId: item.id,
        adjustment: +item.adjustment,
        flatRateAdjustment: +item.flatRateAdjustment,
        totalAdjustment: +item.totalAdjustment,
      }));
  };

  const onSetAdjustmentPerQuantity = () => {
    return [
      ...adjustmentPerQuantityPayload.value,
      ...adjustmentPerQuantityPayloadAnotherRegion.value,
    ].map((item) => {
      const {
        costingSummaryId,
        adjustment,
        flatRateAdjustment,
        totalAdjustment,
      } = item;
      return QuoteService.setAdjustmentPerQuantity(costingSummaryId, {
        adjustment,
        flatRateAdjustment,
        totalAdjustment,
      });
    });
  };

  const onSetMinGap = () => {
    return new Promise<void>((resolve): void | Promise<void> => {
      if (!calculatedPayload.value) {
        return resolve();
      }
      return QuoteService.setMinAdjustmentGap(
        costingId.value,
        calculatedPayload.value
      ).then((res) => {
        if (isAdminSummary.value) {
          adjustmentGap.value = res.data.data.adjustmentPriceGap;
          isGapActive.value = res.data.data.isPriceGapActive;
          adjustmentCost.value = res.data.data.adjustmentPrice;
          isAdjustmentCostActive.value = res.data.data.isAdjustmentPriceActive;
        } else {
          adjustmentGap.value = res.data.data.adjustmentCostGap;
          isGapActive.value = res.data.data.isCostGapActive;
          adjustmentCost.value = res.data.data.adjustmentCost;
          isAdjustmentCostActive.value = res.data.data.isAdjustmentCostActive;
        }
        resolve();
      });
    });
  };

  const onCalculateAdjustmentPerQuantityPreview = (data: {
    index: number | null;
    adjustment: number | null;
    rate: number;
    isRateActive: boolean;
  }) => {
    if (data.index !== null) {
      const existingPayloadIndex = adjustmentPerQuantityPayload.value.findIndex(
        (item) =>
          item.costingSummaryId ===
          costingQuantityListWithAdjustments.value[data.index as number].id
      );
      if (existingPayloadIndex !== -1) {
        adjustmentPerQuantityPayload.value[existingPayloadIndex].adjustment =
          data.adjustment || 0;
      } else {
        adjustmentPerQuantityPayload.value.push({
          costingSummaryId:
            costingQuantityListWithAdjustments.value[data.index].id,
          adjustment: data.adjustment || 0,
          flatRateAdjustment: 0,
          totalAdjustment: 0,
        });
      }
    }
    if (!adjustmentPerQuantityPayload.value.length) {
      setInitAdjustmentPerQuantityPayload();
    }

    isAdjustmentCostActive.value = data.isRateActive;
    adjustmentCost.value = `${data.rate / 100}`;
    calculatedPayload.value = getCalculatedPayload(
      {
        gap: +adjustmentGap.value * 100,
        isGapActive: isGapActive.value,
        rate: data.rate,
        isRateActive: isAdjustmentCostActive.value,
      },
      isAdminSummary.value,
      partCosting.value
    );

    return QuoteService.getSummaryPreviewWithAdjustmentPerQuantity(
      costingId.value,
      adjustmentPerQuantityPayload.value.map((item) => ({
        costingSummaryId: item.costingSummaryId,
        adjustment: item.adjustment,
      })),
      calculatedPayload.value,
      currencyId.value
    ).then((res) => {
      onUpdateCostingQuantityListWithAdjustments(res);
    });
  };

  const onClickOutsideDropdown = () => {
    adjustmentPerQuantityPayload.value = [];
    showAddAdjustmentMenu.value = false;
    setDefaultValues();
  };

  const setDefaultValues = () => {
    if (isAdminSummary.value) {
      adjustmentGap.value = partCosting.value.adjustmentPriceGap;
      isGapActive.value = partCosting.value.isPriceGapActive;
      isAdjustmentCostActive.value = partCosting.value.isAdjustmentPriceActive;
      adjustmentCost.value = partCosting.value.adjustmentPrice;
      explanationCost.value = partCosting.value.explanationPrice || "";
      return;
    }
    adjustmentGap.value = partCosting.value.adjustmentCostGap;
    isGapActive.value = partCosting.value.isCostGapActive;
    isAdjustmentCostActive.value = partCosting.value.isAdjustmentCostActive;
    adjustmentCost.value = partCosting.value.adjustmentCost;
    explanationCost.value = partCosting.value.explanationCost || "";
  };

  return {
    onSetAdjustmentPerQuantity,
    showAddAdjustmentMenu,
    adjustmentGap,
    isGapActive,
    adjustmentCost,
    isAdjustmentCostActive,
    explanationCost,
    isAdjustmentModified,
    onCalculateSummaryPreview,
    setInitAdjustmentPerQuantityPayload,
    onSetMinGap,
    onCalculateAdjustmentPerQuantityPreview,
    onClickOutsideDropdown,
  };
};
