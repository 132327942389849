import { transformDecimalToPercents } from "@/composables/useTransformDecimalToPercents";
import { getThousandWithCommas } from "@/composables/maskComposable";

export default () => {
  const getCostValue = (
    value?: string | number,
    currencySign = "$",
    showCurrencySignAfterNumber = true
  ) => {
    return value
      ? `${
          showCurrencySignAfterNumber ? "" : currencySign
        }${getThousandWithCommas(value)} ${
          showCurrencySignAfterNumber ? currencySign : ""
        }`
      : "-";
  };
  const getDateValue = (value?: string | number) => {
    return value ? `${getThousandWithCommas(value)} d` : "-";
  };

  const checkZeroValue = (value?: string | number, showZeroValue = false) =>
    showZeroValue && (value || value === 0);

  const getPercentValue = (value?: string | number, showZeroValue = false) => {
    return value || checkZeroValue(value, showZeroValue)
      ? `${transformDecimalToPercents(+(value || 0))}%`
      : "-";
  };

  return {
    getCostValue,
    getDateValue,
    checkZeroValue,
    getPercentValue,
  };
};
