const checkForSearchQueryMatchesInArrayList = (
  arrayList: string[][],
  queryArray: string[]
): boolean => {
  return !!queryArray.filter((queryEl) => {
    const queryElLowerCase = queryEl.toLowerCase();
    return arrayList.some((array) =>
      array.some((el) => el.toLowerCase().includes(queryElLowerCase))
    );
  }).length;
};

export default checkForSearchQueryMatchesInArrayList;
