import { createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c94826b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-with-buttons" }
const _hoisted_2 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["NButton"], {
      class: "input-button",
      onClick: $setup.onMinus,
      disabled: $setup.showValidationMessage
    }, {
      icon: _withCtx(() => [
        _createVNode($setup["NIcon"], {
          component: $setup.Minus,
          size: 12
        }, null, 8, ["component"])
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode($setup["NInputNumber"], {
      class: "adjustment-input",
      value: $setup.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      showButton: false,
      placeholder: "",
      onBlur: $setup.onUpdate,
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($event.target.blur()), ["enter"]))
    }, {
      suffix: _withCtx(() => [
        _createTextVNode("%")
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode($setup["NButton"], {
      class: "input-button plus-button",
      onClick: $setup.onPlus
    }, {
      icon: _withCtx(() => [
        _createVNode($setup["NIcon"], {
          component: $setup.Plus,
          size: 12
        }, null, 8, ["component"])
      ]),
      _: 1
    }),
    ($setup.showValidationMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.notLessThanMessage("Adjustment", $props.minValue)) + "%", 1))
      : _createCommentVNode("", true)
  ]))
}