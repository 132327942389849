const checkForSearchQueryMatchesInArray = (
  fieldArray: string[],
  queryArray: string[]
): boolean => {
  return !!queryArray.filter((queryEl) => {
    const queryElLowerCase = queryEl.toLowerCase();
    return fieldArray.some((el) => el.toLowerCase().includes(queryElLowerCase));
  }).length;
};

export default checkForSearchQueryMatchesInArray;
