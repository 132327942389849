import { Ref } from "vue";
import { FormItemRule } from "naive-ui";
import { requiredMessage } from "@/composables/validationMessages";

const requiredFieldVerificationRules = (
  fieldName: string,
  isDirty: Ref<boolean>,
  errorMap: Ref<Map<string, string>>,
  onValidationReject: (
    reject: (reason?: Error) => void,
    fieldName: string,
    errorMessage: string
  ) => void,
  onValidationResolve: (
    resolve: (value: void | PromiseLike<void>) => void,
    fieldName: string
  ) => void
): FormItemRule[] => {
  return [
    {
      required: true,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!isDirty.value) {
            onValidationResolve(resolve, fieldName);
            return;
          }
          if (!value) {
            onValidationReject(reject, fieldName, requiredMessage(fieldName));
          } else {
            onValidationResolve(resolve, fieldName);
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default requiredFieldVerificationRules;
